// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-article-page-js": () => import("./../../../src/components/article-page.js" /* webpackChunkName: "component---src-components-article-page-js" */),
  "component---src-components-topic-index-page-js": () => import("./../../../src/components/topic-index-page.js" /* webpackChunkName: "component---src-components-topic-index-page-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-android-index-js": () => import("./../../../src/pages/android/index.js" /* webpackChunkName: "component---src-pages-android-index-js" */),
  "component---src-pages-android-thanks-index-js": () => import("./../../../src/pages/android/thanks/index.js" /* webpackChunkName: "component---src-pages-android-thanks-index-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-coach-index-js": () => import("./../../../src/pages/coach/index.js" /* webpackChunkName: "component---src-pages-coach-index-js" */),
  "component---src-pages-coach-signup-full-index-js": () => import("./../../../src/pages/coach/signup/full/index.js" /* webpackChunkName: "component---src-pages-coach-signup-full-index-js" */),
  "component---src-pages-coach-signup-index-js": () => import("./../../../src/pages/coach/signup/index.js" /* webpackChunkName: "component---src-pages-coach-signup-index-js" */),
  "component---src-pages-coach-signup-notify-index-js": () => import("./../../../src/pages/coach/signup/notify/index.js" /* webpackChunkName: "component---src-pages-coach-signup-notify-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-courses-walk-like-a-champ-index-js": () => import("./../../../src/pages/courses/walk-like-a-champ/index.js" /* webpackChunkName: "component---src-pages-courses-walk-like-a-champ-index-js" */),
  "component---src-pages-feedback-index-js": () => import("./../../../src/pages/feedback/index.js" /* webpackChunkName: "component---src-pages-feedback-index-js" */),
  "component---src-pages-feedback-thanks-js": () => import("./../../../src/pages/feedback/thanks.js" /* webpackChunkName: "component---src-pages-feedback-thanks-js" */),
  "component---src-pages-foster-index-js": () => import("./../../../src/pages/foster/index.js" /* webpackChunkName: "component---src-pages-foster-index-js" */),
  "component---src-pages-foster-iphone-index-js": () => import("./../../../src/pages/foster/iphone/index.js" /* webpackChunkName: "component---src-pages-foster-iphone-index-js" */),
  "component---src-pages-foster-success-index-js": () => import("./../../../src/pages/foster/success/index.js" /* webpackChunkName: "component---src-pages-foster-success-index-js" */),
  "component---src-pages-help-index-js": () => import("./../../../src/pages/help/index.js" /* webpackChunkName: "component---src-pages-help-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-press-index-js": () => import("./../../../src/pages/press/index.js" /* webpackChunkName: "component---src-pages-press-index-js" */),
  "component---src-pages-privacy-index-js": () => import("./../../../src/pages/privacy/index.js" /* webpackChunkName: "component---src-pages-privacy-index-js" */),
  "component---src-pages-research-index-js": () => import("./../../../src/pages/research/index.js" /* webpackChunkName: "component---src-pages-research-index-js" */),
  "component---src-pages-research-thanks-js": () => import("./../../../src/pages/research/thanks.js" /* webpackChunkName: "component---src-pages-research-thanks-js" */),
  "component---src-pages-terms-index-js": () => import("./../../../src/pages/terms/index.js" /* webpackChunkName: "component---src-pages-terms-index-js" */),
  "component---src-pages-vip-index-js": () => import("./../../../src/pages/vip/index.js" /* webpackChunkName: "component---src-pages-vip-index-js" */),
  "component---src-pages-vip-iphone-index-js": () => import("./../../../src/pages/vip/iphone/index.js" /* webpackChunkName: "component---src-pages-vip-iphone-index-js" */),
  "component---src-pages-vip-success-index-js": () => import("./../../../src/pages/vip/success/index.js" /* webpackChunkName: "component---src-pages-vip-success-index-js" */)
}

